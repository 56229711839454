import React, { FC } from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
 
export interface TooltipProps {
  tip: string
  link?: string
  append?: string
}

const contentStyle = {
  minHeight: 1,
  padding: 16,
  fontSize: 14,
  fontFamily: 'sans-serif'
}

const contentTextStyle = {
  textDecoration: 'underline',
  cursor: 'pointer',
}

export const ATooltip: FC<TooltipProps> = ({ children, ...props }) => (
  <Tooltip
    placement="bottom"
    overlay={
      <div>
        <a href={props.link} style={{ color: 'white', textDecoration: 'none' }}>{props.tip}</a>
        { props.append &&
          <div>
            <hr />
            <i>{props.append}</i> 
          </div>
        }
      </div>
    }
    overlayInnerStyle={contentStyle}
    overlayStyle={{ maxWidth: 275 }}
  >
    <span style={contentTextStyle}>
      <a href={props.link} style={{ color: '#448AFF', fontWeight: 'bold' }}>
        {children}
      </a>
    </span>
  </Tooltip>
)