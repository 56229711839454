import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ATooltip } from '../components/ATooltip';
import fact from '../content/facts.json';
import stmt from '../content/tdlrstatements.json';
import links from '../content/links.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h3 {...{
      "id": "c5-additional-documentation-is-required-to-supplement-palms-to-properly-transfer-a-student-or-for-a-student-to-have-successfully-completed-a-subject-per-11761e"
    }}>{`C5: `}<strong parentName="h3"><em parentName="strong">{`Additional documentation is required to supplement PALMS to properly transfer a student or for a student to have successfully completed a subject, per 117.61(e).`}</em></strong></h3>
    <div style={{
      "marginBottom": "54px"
    }}>
  PALMS data lacks the data required to be considered a transcript to a school receiving the PALMS 
  data <ATooltip tip={stmt.t1.quote} append={stmt.t1.credit} link={links.t1} mdxType="ATooltip">(T1)</ATooltip>. Also, there is not any indication if these hours trigger the 
  additional record keeping requirements stated in <b>117.61(d)</b>. This is further supported by the fact that successfully completed hours listed in alone are not 
  enough to convey successful completion of required curriculum <ATooltip tip={fact.a12} link={links.a12} mdxType="ATooltip">(A12)</ATooltip>. A 
  student may have successfully completed the first part of a subject multiple times, or the new school may teach a subject in a different order. Both result in the 
  correct number of hours, or more, but without ensuring coverage of curriculum <ATooltip tip={fact.a12} link={links.a12} mdxType="ATooltip">(A12)</ATooltip>.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      