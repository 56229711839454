import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ATooltip } from '../components/ATooltip';
import fact from '../content/facts.json';
import links from '../content/links.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h3 {...{
      "id": "c3-tdlr-has-not-yet-implemented-the-reporting-requirements-for-4552035"
    }}>{`C3: `}<strong parentName="h3"><em parentName="strong">{`TDLR has not yet implemented the reporting requirements for 455.2035`}</em></strong></h3>
    <div style={{
      "marginBottom": "54px"
    }}>
  Conclusion 2 means that reporting cannot be done through PALMS. TDLR has provided no other mechanisms to report. This is further supported 
  by the fact that only the school owner has an account for logging into PALMS <ATooltip tip={fact.a7} link={links.a7} mdxType="ATooltip">(A7)</ATooltip>, and 
  it would not be reasonable to expect a monthly reporting on a single individual in an entire organization without redundancy.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      