import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ATooltip } from '../components/ATooltip';
import acts from '../content/acts.json';
import links from '../content/links.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h3 {...{
      "id": "t1-palms-is-considered-a-transcript-acceptable-to-the-department"
    }}>{`T1: `}<strong parentName="h3"><em parentName="strong">{`PALMS is considered a transcript acceptable to the department.`}</em></strong></h3>
    <div style={{
      "marginTop": "-16px",
      "marginBottom": "18px"
    }}><i>TDLR to the Office of Rep. Angie Chen Button: 2/24/2021</i></div>
    <div>
  <span><b>Determination: </b></span>
  <span style={{
        "color": "#7CB342"
      }}>TRUE</span>
    </div>
    <div style={{
      "marginBottom": "54px"
    }}>
  <b>Rationale: </b> PALMS meets the definitions of <b>117.62(d)</b>, assuming the most casual of definitions for “pass/fail score” and “list of subjects”. 
   However, this only meets the definition of a transcript when TDLR receives the data. When a school receives data from PALMS,
   it is missing the “name and license number of massage therapy educational program,”. 
   The the “pass/fail score” can be assumed if all hours are considered “pass”. 
  <div style={{
        "marginTop": "16px"
      }}>
    <b>Note:</b> It is unlikely that any other organization, federal state or private, would consider this a transcript, however
    this being True is required for TDLR's action <ATooltip tip={acts.act1.stmt} append={acts.act1.date} link={links.ACT1} mdxType="ATooltip">(ACT1)</ATooltip>
    and <ATooltip tip={acts.act2.stmt} append={acts.act2.date} link={links.ACT2} mdxType="ATooltip">(ACT2)</ATooltip> to be lawful.
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      