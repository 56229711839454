import T1 from "../../../../../src/tdlr-accuracy/t1.mdx";
import T2 from "../../../../../src/tdlr-accuracy/t2.mdx";
import T3 from "../../../../../src/tdlr-accuracy/t3.mdx";
import T4 from "../../../../../src/tdlr-accuracy/t4.mdx";
import T5 from "../../../../../src/tdlr-accuracy/t5.mdx";
import T6 from "../../../../../src/tdlr-accuracy/t6.mdx";
import T7 from "../../../../../src/tdlr-accuracy/t7.mdx";
import T8 from "../../../../../src/tdlr-accuracy/t8.mdx";
import * as React from 'react';
export default {
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  T8,
  React
};