import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ATooltip } from '../components/ATooltip';
import stmt from '../content/tdlrstatements.json';
import links from '../content/links.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h3 {...{
      "id": "t7-tdlr-is-required-to-confirm-the-student-has-completed-the-required-500-hour-massage-therapy-course-to-meet-the-licensure-requirements-for-a-massage-therapist-license-now-licensed-massage-schools-must-use-palms-to-report-a-students-completed-hours-and-the-licensed-massage-school-is-no-longer-required-to-submit-a-paper-transcript-to-tdlr-for-the-verification-of-the-students-hours"
    }}>{`T7: `}<strong parentName="h3"><em parentName="strong">{`“TDLR is required to confirm the student has completed the required 500-hour massage therapy course to meet the licensure requirements for a massage therapist license. Now, licensed massage schools must use PALMS to report a student’s completed hours and the licensed massage school is no longer required to submit a paper transcript to TDLR for the verification of the student’s hours.”`}</em></strong></h3>
    <div style={{
      "marginTop": "-16px",
      "marginBottom": "18px"
    }}><i>Notice from TDLR: 4/23/2021</i></div>
    <div>
  <span><b>Determination: </b></span>
  <span style={{
        "color": "#7CB342"
      }}>TRUE</span>
    </div>
    <div style={{
      "marginBottom": "48px"
    }}>
  <b>Rationale:</b> PALMS submissions are considered a transcript per <ATooltip tip={stmt.t1.quote} link={links.t1} append={stmt.t1.credit} mdxType="ATooltip">TDLR Statement 1</ATooltip>. Students 
  are required to complete a minimum 500-hour course per <b>117.20</b>.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      