import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ATooltip } from '../components/ATooltip';
import fact from '../content/facts.json';
import links from '../content/links.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h3 {...{
      "id": "c2-there-is-no-mechanism-for-report-daily-attendance-through-palms"
    }}>{`C2: `}<strong parentName="h3"><em parentName="strong">{`There is no mechanism for report “daily attendance” through PALMS`}</em></strong></h3>
    <div style={{
      "marginBottom": "54px"
    }}>
  Attendance requires the ability to track both the hours a student is attended and the absences <ATooltip tip={fact.a1} link={links.a1} mdxType="ATooltip">(A1)</ATooltip> &nbsp;
  <ATooltip tip={fact.a4} link={links.a4} mdxType="ATooltip">(A4)</ATooltip>. PALMS has no way to do this <ATooltip tip={fact.a4} link={links.a4} mdxType="ATooltip">(A4)</ATooltip> &nbsp;
  <ATooltip tip={fact.a5} link={links.a5} mdxType="ATooltip">(A5)</ATooltip>. This is further supported by the fact that PALMS only tracks hours in the format matched directly used 
  for determining licensing eligibility <ATooltip tip={fact.a11} link={links.a11} mdxType="ATooltip">(A11)</ATooltip>, and not the way it’s taught (normal definition of attendance), 
  nor the way TDLR lays it out in their required curriculum per <b>117.59(a)</b>. Further supported by the intended purpose of PALMS is for implementation of <b>455.2035</b>, 
  which is for combating human trafficking.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      