import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ATooltip } from '../components/ATooltip';
import stmt from '../content/tdlrstatements.json';
import fact from '../content/facts.json';
import oconclusion from '../content/oconclusions.json';
import links from '../content/links.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h3 {...{
      "id": "t4-the-most-important-part-of-the-student-permit-is-tracking-the-student--i-would-say-that-it-is-enormously-successful--students-are-free-to-transfer-between-schools-should-they-relocate-within-texas-hours-now-have-a-place-to-be-tracked-and-verified-electronically-clearing-any-burdens-of-paper-only-type-requirements--i-can-share-a-story-of-another-office-that-contacted-me-regarding-a-student-who-did-in-fact-have-to-move-during-schooling--the-student-easily-enrolled-in-the-new-school-and-all-previous-hours-earned-were-credited-allowing-the-student-to-immediately-continue-progressing-towards-licensure"
    }}>{`T4: `}<strong parentName="h3"><em parentName="strong">{`“The most important part of the student permit is tracking the student.  I would say that it is enormously successful.  Students are free to transfer between schools should they relocate within Texas, hours now have a place to be tracked and verified electronically, clearing any burdens of “paper only” type requirements.  I can share a story of another office that contacted me regarding a student who did in fact have to move during schooling.  The student easily enrolled in the new school and all previous hours earned were credited, allowing the student to immediately continue progressing towards licensure.”`}</em></strong></h3>
    <div style={{
      "marginTop": "-16px",
      "marginBottom": "18px"
    }}><i>TDLR to the Office of Senator Angela Paxton: 3/3/2021</i></div>
    <div>
  <span><b>Determination: </b></span>
  <span style={{
        "color": "#C62828"
      }}>FALSE</span>
    </div>
    <div style={{
      "marginBottom": "54px"
    }}>
  <b>Rationale:</b> Per <ATooltip tip={stmt.t2.quote} append={stmt.t2.credit} link={links.t2} mdxType="ATooltip">TDLR Statement 2</ATooltip> and &nbsp;
  <ATooltip tip={oconclusion.c4.quote} link={links.c4} append={oconclusion.c4.dispute} mdxType="ATooltip">Operational Conclusion 4</ATooltip>, without additional coordination, the mentioned 
  student move would have been performed without proper checks in place to ensure it was done correctly.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      