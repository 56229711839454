import Conclusion1 from "../../../../../src/operational-conclusions/c1.mdx";
import Conclusion2 from "../../../../../src/operational-conclusions/c2.mdx";
import Conclusion3 from "../../../../../src/operational-conclusions/c3.mdx";
import Conclusion4 from "../../../../../src/operational-conclusions/c4.mdx";
import Conclusion5 from "../../../../../src/operational-conclusions/c5.mdx";
import * as React from 'react';
export default {
  Conclusion1,
  Conclusion2,
  Conclusion3,
  Conclusion4,
  Conclusion5,
  React
};