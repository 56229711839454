import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "t2-the-hours-entered-in-palms-will-be-used-in-lieu-of-an-official-transcript-for-the-purpose-of-obtaining-a-license-or-transferring-to-another-licensed-texas-school"
    }}>{`T2: `}<strong parentName="h3"><em parentName="strong">{`The hours entered in PALMS will be used in lieu of an official transcript for the purpose of obtaining a license or transferring to another licensed Texas school.`}</em></strong></h3>
    <div style={{
      "marginTop": "-16px",
      "marginBottom": "18px"
    }}><i>TDLR Education: 11/12/2020</i></div>
    <div>
  <span><b>Determination: </b></span>
  <span style={{
        "color": "#C62828"
      }}>FALSE</span>
    </div>
    <p><strong parentName="p">{`Rationale:`}</strong>{` Prior to April 1, 2021, `}<strong parentName="p">{`117.20`}</strong>{` did not permit anything to be used in lieu of a transcript for the purposes of licensure. This is not being used in lieu of a
transcript; it is a transcript. Transferring to another school is not possible with PALMS without additional documentation due to requirements of `}<strong parentName="p">{`117.61(d)`}</strong>{` to confirm
full-coverage of required learning stated in TDLR’s ‘Massage Therapy Basic Curriculum Outline - 500 Hour Course’`}</p>
    <div style={{
      "marginBottom": "54px"
    }}></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      