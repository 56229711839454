import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ATooltip } from '../components/ATooltip';
import fact from '../content/facts.json';
import stmt from '../content/tdlrstatements.json';
import links from '../content/links.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h3 {...{
      "id": "c1-all-hours-entered-into-palms-should-be-hours-that-are-successfully-completed"
    }}>{`C1: `}<strong parentName="h3"><em parentName="strong">{`All hours entered into PALMS should be hours that are “successfully completed”`}</em></strong></h3>
    <div style={{
      "marginBottom": "54px"
    }}>
  TDLR Statement T1 <ATooltip tip={stmt.t1.quote} append={stmt.t1.credit} link={links.t1} mdxType="ATooltip">(T1)</ATooltip> states that PALMS is a transcript. 
  In order for it to be considered a transcript, it must contain a “pass/fail” score. This requires more fidelity than pure 
  attendance <ATooltip tip={fact.a3} link={links.a3} mdxType="ATooltip">(A3)</ATooltip>. However, PALMS lacks any direct 
  mechanisms <ATooltip tip={fact.a5} link={links.a5} mdxType="ATooltip">(A5)</ATooltip> to do so. Marking a student as graduated is not an acceptable indicator 
  because a student that has transferred will have had all their hours marked as terminated <ATooltip tip={fact.a9} link={links.a9} mdxType="ATooltip">(A9)</ATooltip> regardless 
  of successful completion, and they will still contribute to licensure <ATooltip tip={fact.a10} link={links.a10} mdxType="ATooltip">(A10)</ATooltip>. Therefore, 
  the only way to have a “pass/fail” score within PALMS is to state that all hours entered are “successfully completed”.
  This is further supported by TDLR’s signaled intent for these hours to assist in transitioning between schools 
  <ATooltip tip={stmt.t2.quote} append={stmt.t2.credit} link={links.t2} mdxType="ATooltip">(T2)</ATooltip> <ATooltip tip={stmt.t4.quote} append={stmt.t4.credit} link={links.t4} mdxType="ATooltip">(T4)</ATooltip>.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      