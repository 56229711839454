import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "act2-tdlr-will-not-issue-a-massage-therapy-license-to-students-enrolled-in-palms-until-marked-as-graduated-despite-receiving-a-transcript-in-another-manner"
    }}>{`ACT2: `}<strong parentName="h3"><em parentName="strong">{`TDLR will not issue a massage therapy license to students enrolled in PALMS until marked as graduated despite receiving a transcript in another manner.`}</em></strong></h3>
    <div style={{
      "marginTop": "-16px",
      "marginBottom": "18px"
    }}><i>2020 - Present</i></div>
    <div>
  <span><b>Determination: </b></span>
  <span style={{
        "color": "#7CB342"
      }}>LAWFUL</span>
    </div>
    <p><strong parentName="p">{`Rationale:`}</strong>{` `}<strong parentName="p">{`117.20`}</strong>{` does not explicitly require any data be entered into PALMS to issue a transcript, and there are no specific stipulations allowing TDLR to deny
licensure to otherwise eligible applicants.  However, `}<strong parentName="p">{`127.20(b)`}</strong>{` requires the use of department approved forms, it can be reasoned that the only approved form for
transcripts for students is PALMS. Which makes this a lawful action.`}</p>
    <div style={{
      "marginBottom": "54px"
    }}></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      