import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ATooltip } from '../components/ATooltip';
import stmt from '../content/tdlrstatements.json';
import links from '../content/links.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h3 {...{
      "id": "t5-however-palms-is-only-to-be-utilized-for-the-enrollment-of-students-and-the-reporting-of-hours-we-do-not-collect-tuition-information-or-transcript-information-for-students"
    }}>{`T5: `}<strong parentName="h3"><em parentName="strong">{`“However, PALMS is only to be utilized for the enrollment of students and the reporting of hours. We do not collect tuition information or transcript information for students.”`}</em></strong></h3>
    <div style={{
      "marginTop": "-16px",
      "marginBottom": "18px"
    }}><i>TDLR Education to Ke Kino Massage Academy: 4/1/2021</i></div>
    <div>
  <span><b>Determination: </b></span>
  <span style={{
        "color": "#C62828"
      }}>FALSE</span>
    </div>
    <div style={{
      "marginBottom": "54px"
    }}>
  <b>Rationale:</b> Directly contradicts <ATooltip tip={stmt.t1.quote} append={stmt.t1.credit} link={links.t1} mdxType="ATooltip">TDLR Statment T1</ATooltip>.
  Additionally, if this were true, TDLR would have been exceeding their legal authority <ATooltip tip={'Click to view these acts'} link={'actionlegality'} mdxType="ATooltip">(ACT1, ACT2).</ATooltip>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      