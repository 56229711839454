import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ATooltip } from '../components/ATooltip';
import stmt from '../content/tdlrstatements.json';
import links from '../content/links.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h3 {...{
      "id": "t3-this-change-allows-for-massage-schools-in-texas-to-send-a-record-of-coursework-electronically-to-the-department-through-the-new-palms-system-for-reporting-hours-and-enrolled-students"
    }}>{`T3: `}<strong parentName="h3"><em parentName="strong">{`“This change allows for massage schools in Texas to send a record of coursework electronically to the Department, through the new PALMS system for reporting hours and enrolled students.”`}</em></strong></h3>
    <div style={{
      "marginTop": "-16px",
      "marginBottom": "18px"
    }}><i>Published by TDLR in the Texas Register: 11/20/2020</i></div>
    <div>
  <span><b>Determination: </b></span>
  <span style={{
        "color": "#C62828"
      }}>FALSE</span>
    </div>
    <p><strong parentName="p">{`Rationale:`}</strong>{` No change was required. Schools were already being required (not allowed) to send this information through PALMS prior to the proposed
rules going into effect on 4/1/2021. This data was being used for licensure.`}</p>
    <div style={{
      "marginBottom": "54px"
    }}>
  <b>Rationale</b> Per <ATooltip tip={stmt.t2.quote} append={stmt.t2.credit} link={links.t2} mdxType="ATooltip">TDLR Statement 2</ATooltip>, Additional Documentation is required to 
  correctly transfer a student over to ensure <b>11761(d)</b> requirements, and to confirm full-coverage of TDLR’s required curriculum all schools are required to follow 
  per <b>117.59(a)</b>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      