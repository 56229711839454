import { ATooltip } from "../../../../../src/components/ATooltip";
import stmt from "../../../../../src/content/tdlrstatements.json";
import oconclusion from "../../../../../src/content/oconclusions.json";
import links from "../../../../../src/content/links.json";
import * as React from 'react';
export default {
  ATooltip,
  stmt,
  oconclusion,
  links,
  React
};