import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ATooltip } from '../components/ATooltip';
import fact from '../content/facts.json';
import stmt from '../content/tdlrstatements.json';
import links from '../content/links.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h3 {...{
      "id": "cs2-tdlrs-efforts-are-resulting-in-wiping-out-student-access-to-low-cost-loans-and-tuition"
    }}>{`CS2: `}<strong parentName="h3"><em parentName="strong">{`TDLRs efforts are resulting in wiping out student access to low cost loans and tuition`}</em></strong></h3>
    <div style={{
      "marginBottom": "16px"
    }}>
  Texas requires that massage schools in Texas offer a 500 hour course, which is below the federal threshold for students to qualify for Federal Student Aid. 
  Many Texas massage schools compensate for this discrepancy by offering low or interest free loans and payment plans, secured using the students transcript and licensure as collateral.   
  This option is heavily used, especially by socio-economically disadvantaged demographics, and it is generally considered a superior alternative to using a credit card or 
  loansharking mechanism.
    </div>
    <div style={{
      "marginBottom": "16px"
    }}>
  TDLR is ending this practice by removing a students ability to use their licensure as collateral. 
  Per <ATooltip tip={stmt.t8.quote} link={links.t8} append={stmt.t8.credit} mdxType="ATooltip">TDLR Statement T8</ATooltip>, Massage schools are not 
  large businesses able to handle this sort of debt burden on unsecured debt.
    </div>
    <div style={{
      "marginBottom": "54px"
    }}>
  The net effect will be pushing potential students out of the industry, to enroll in unncessarily long programs so they qualify for federal aid, or to use high interest loans.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      