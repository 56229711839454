import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ATooltip } from '../components/ATooltip';
import fact from '../content/facts.json';
import links from '../content/links.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h3 {...{
      "id": "cs1-tdlrs-response-to-tex-occ-code-455161-and-4552035-is-not-effective-for-either-the-publics-intended-purpose-or-tdlrs-internal-goals"
    }}>{`CS1: `}<strong parentName="h3"><em parentName="strong">{`TDLRs response to Tex. Occ. Code 455.161 and 455.2035 is not effective for either the publics intended purpose or TDLR’s internal goals.`}</em></strong></h3>
    <div style={{
      "marginBottom": "54px"
    }}>
  PALMS was created in response to the legislative addition of <b>Tex. Occ. Code 455.161</b> and <b>455.2035</b> <ATooltip tip={fact.a13} link={links.a13} mdxType="ATooltip">(A13)</ATooltip>, 
  yet does not correctly implement these. It could be argued that permits are being successfully implemented, but TDLR relies on the school to establish identity 
  of students <ATooltip tip={fact.a14} link={links.a14} mdxType="ATooltip">(A14)</ATooltip>, which makes it ineffective for the intended purpose of combating human 
  trafficking <ATooltip tip={fact.a15} link={links.a15} mdxType="ATooltip">(A15)</ATooltip>. TDLR’s goals do not seem to reflect the 'combating human trafficking' thesis, and 
  they have their own stated goals <br /> <ATooltip tip={'Click to view these statemtents.'} link={'tdlraccuracy'} mdxType="ATooltip">(TDLR Statements T1-T4, </ATooltip> 
  <ATooltip tip={'Click to view these acts.'} link={'actionlegality'} mdxType="ATooltip">TDLR Acts ACT1, ACT2)</ATooltip>. However, it has not been effective for those purposes either.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      