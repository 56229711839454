// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-action-legality-act-1-mdx": () => import("./../../../../src/action-legality/ACT1.mdx" /* webpackChunkName: "component---src-action-legality-act-1-mdx" */),
  "component---src-action-legality-act-2-mdx": () => import("./../../../../src/action-legality/ACT2.mdx" /* webpackChunkName: "component---src-action-legality-act-2-mdx" */),
  "component---src-actionlegality-mdx": () => import("./../../../../src/actionlegality.mdx" /* webpackChunkName: "component---src-actionlegality-mdx" */),
  "component---src-facttable-mdx": () => import("./../../../../src/facttable.mdx" /* webpackChunkName: "component---src-facttable-mdx" */),
  "component---src-index-mdx": () => import("./../../../../src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---src-operational-conclusions-c-1-mdx": () => import("./../../../../src/operational-conclusions/c1.mdx" /* webpackChunkName: "component---src-operational-conclusions-c-1-mdx" */),
  "component---src-operational-conclusions-c-2-mdx": () => import("./../../../../src/operational-conclusions/c2.mdx" /* webpackChunkName: "component---src-operational-conclusions-c-2-mdx" */),
  "component---src-operational-conclusions-c-3-mdx": () => import("./../../../../src/operational-conclusions/c3.mdx" /* webpackChunkName: "component---src-operational-conclusions-c-3-mdx" */),
  "component---src-operational-conclusions-c-4-mdx": () => import("./../../../../src/operational-conclusions/c4.mdx" /* webpackChunkName: "component---src-operational-conclusions-c-4-mdx" */),
  "component---src-operational-conclusions-c-5-mdx": () => import("./../../../../src/operational-conclusions/c5.mdx" /* webpackChunkName: "component---src-operational-conclusions-c-5-mdx" */),
  "component---src-operationalconclusions-mdx": () => import("./../../../../src/operationalconclusions.mdx" /* webpackChunkName: "component---src-operationalconclusions-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-strategic-conclusions-cs-1-mdx": () => import("./../../../../src/strategic-conclusions/cs1.mdx" /* webpackChunkName: "component---src-strategic-conclusions-cs-1-mdx" */),
  "component---src-strategic-conclusions-cs-2-mdx": () => import("./../../../../src/strategic-conclusions/cs2.mdx" /* webpackChunkName: "component---src-strategic-conclusions-cs-2-mdx" */),
  "component---src-strategicconclusions-mdx": () => import("./../../../../src/strategicconclusions.mdx" /* webpackChunkName: "component---src-strategicconclusions-mdx" */),
  "component---src-tdlr-accuracy-t-1-mdx": () => import("./../../../../src/tdlr-accuracy/t1.mdx" /* webpackChunkName: "component---src-tdlr-accuracy-t-1-mdx" */),
  "component---src-tdlr-accuracy-t-2-mdx": () => import("./../../../../src/tdlr-accuracy/t2.mdx" /* webpackChunkName: "component---src-tdlr-accuracy-t-2-mdx" */),
  "component---src-tdlr-accuracy-t-3-mdx": () => import("./../../../../src/tdlr-accuracy/t3.mdx" /* webpackChunkName: "component---src-tdlr-accuracy-t-3-mdx" */),
  "component---src-tdlr-accuracy-t-4-mdx": () => import("./../../../../src/tdlr-accuracy/t4.mdx" /* webpackChunkName: "component---src-tdlr-accuracy-t-4-mdx" */),
  "component---src-tdlr-accuracy-t-5-mdx": () => import("./../../../../src/tdlr-accuracy/t5.mdx" /* webpackChunkName: "component---src-tdlr-accuracy-t-5-mdx" */),
  "component---src-tdlr-accuracy-t-6-mdx": () => import("./../../../../src/tdlr-accuracy/t6.mdx" /* webpackChunkName: "component---src-tdlr-accuracy-t-6-mdx" */),
  "component---src-tdlr-accuracy-t-7-mdx": () => import("./../../../../src/tdlr-accuracy/t7.mdx" /* webpackChunkName: "component---src-tdlr-accuracy-t-7-mdx" */),
  "component---src-tdlr-accuracy-t-8-mdx": () => import("./../../../../src/tdlr-accuracy/t8.mdx" /* webpackChunkName: "component---src-tdlr-accuracy-t-8-mdx" */),
  "component---src-tdlraccuracy-mdx": () => import("./../../../../src/tdlraccuracy.mdx" /* webpackChunkName: "component---src-tdlraccuracy-mdx" */)
}

