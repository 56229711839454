import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "act1-tdlr-issued-massage-therapist-licenses-using-palms-data-as-a-source-of-record-for-transcript-information-prior-to-412021"
    }}>{`ACT1: `}<strong parentName="h3"><em parentName="strong">{`TDLR issued massage therapist licenses using PALMS data as a source of record for transcript information prior to 4/1/2021.`}</em></strong></h3>
    <div style={{
      "marginTop": "-16px",
      "marginBottom": "18px"
    }}><i>2020 - 4/1/2021</i></div>
    <div>
  <span><b>Determination: </b></span>
  <span style={{
        "color": "#7CB342"
      }}>LAWFUL</span>
    </div>
    <p><strong parentName="p">{`Rationale:`}</strong>{` Item 1 being TRUE means TDLR has received a transcript so TDLR was issuing licenses only after meeting the requirements of `}<strong parentName="p">{`117.20`}</strong>{`.`}</p>
    <div style={{
      "marginBottom": "54px"
    }}></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      