import React, { FC } from 'react';
 
export interface TooltipProps {
  content: string
}

export const ATextDisplay: FC<TooltipProps> = ({ ...props }) => (
  <div>
    <div style={{ marginBottom: 24 }}>{props.content}</div>
  </div>
)