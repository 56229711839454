import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ATooltip } from '../components/ATooltip';
import stmt from '../content/tdlrstatements.json';
import links from '../content/links.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h3 {...{
      "id": "t8-licensed-massage-schools-may-not-withhold-the-reporting-of-hours-in-palms-because-a-student-owes-money-or-has-unfulfilled-financial-obligations-to-the-licensed-massage-school-the-issue-of-a-student-owing-money-is-a-civil-dispute-in-which-tdlr-is-not-involved-licensed-massage-schools-must-report-successfully-completed-hours-in-palms-regardless-of-the-students-financial-standing-with-their-school"
    }}>{`T8: `}<strong parentName="h3"><em parentName="strong">{`“Licensed massage schools may not withhold the reporting of hours in PALMS because a student owes money or has unfulfilled financial obligations to the licensed massage school. The issue of a student owing money is a civil dispute in which TDLR is not involved. Licensed massage schools must report successfully completed hours in PALMS regardless of the student’s financial standing with their school.”`}</em></strong></h3>
    <div style={{
      "marginTop": "-16px",
      "marginBottom": "18px"
    }}><i>Notice from TDLR: 4/23/2021</i></div>
    <div>
  <span><b>Determination: </b></span>
  <span style={{
        "color": "#2222AA"
      }}>Evaluation in Progress</span>
    </div>
    <div style={{
      "marginBottom": "48px"
    }}>
  <b>Rationale:</b> This is a recent statement, analysis is ongoing to determine the accuracy and ability of TDLR to make this determination. 
  These reports constitute a transcript release per <ATooltip tip={stmt.t1.quote} link={links.t1} append={stmt.t1.credit} mdxType="ATooltip">Statement 1</ATooltip>, so exploration is ongoing to determine if there is a 
  conflict with Federal transcript release rules.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      