import { ATooltip } from "../../../../../src/components/ATooltip";
import fact from "../../../../../src/content/facts.json";
import stmt from "../../../../../src/content/tdlrstatements.json";
import links from "../../../../../src/content/links.json";
import * as React from 'react';
export default {
  ATooltip,
  fact,
  stmt,
  links,
  React
};