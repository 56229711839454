import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ATooltip } from '../components/ATooltip';
import stmt from '../content/tdlrstatements.json';
import oconclusion from '../content/oconclusions.json';
import links from '../content/links.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h3 {...{
      "id": "c4-schools-may-wait-to-input-of-data-into-palms-until-all-terms-of-enrollment-are-met-per-11764d"
    }}>{`C4: `}<strong parentName="h3"><em parentName="strong">{`School’s may wait to input of data into PALMS until all terms of enrollment are met per 117.64(d)`}</em></strong></h3>
    <div style={{
      "marginBottom": "54px"
    }}>
  <div style={{
        "color": "#C62828",
        "fontWeight": "bold"
      }}>UNDER REVIEW: On April 23rd, TDLR issued a statement directly contradicting this conclusion. Evaluation on its accuracy is in progress.</div>
  PALMS is considered a transcript per <ATooltip tip={stmt.t1.quote} append={stmt.t1.credit} link={links.t1} mdxType="ATooltip">TDLR Statement T1</ATooltip>, and TDLR has not yet 
  implemented the reporting requirements <ATooltip tip={oconclusion.c3.quote} link={links.c3} mdxType="ATooltip">(C3)</ATooltip> that would force a release of some data.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      