import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ATooltip } from '../components/ATooltip';
import stmt from '../content/tdlrstatements.json';
import oconclusion from '../content/oconclusions.json';
import links from '../content/links.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h3 {...{
      "id": "t6-16-texas-admin-code-11767b-requires-licensed-massage-schools-to-report-each-students-accrued-hours-every-month-to-tdlr-these-hours-are-reported-through-palms-this-allows-schools-to-fulfill-their-obligation-under-texas-occupation-code-4552035a-to-report-student-hours-to-tdlr"
    }}>{`T6: `}<strong parentName="h3"><em parentName="strong">{`“16 Texas Admin. Code 117.67(b) requires licensed massage schools to report each student’s accrued hours every month to TDLR. These hours are reported through PALMS. This allows schools to fulfill their obligation under Texas Occupation Code 455.2035(a) to report student hours to TDLR”`}</em></strong></h3>
    <div style={{
      "marginTop": "-16px",
      "marginBottom": "18px"
    }}><i>Notice from TDLR: 4/23/2021</i></div>
    <div>
  <span><b>Determination: </b></span>
  <span style={{
        "color": "#C62828"
      }}>FALSE/Misleading</span>
    </div>
    <div style={{
      "marginBottom": "54px"
    }}>
  <b>Rationale:</b> TDLR is substituting the word 'attendance' for the word 'hours', they are not the same thing.  
  Also per <ATooltip tip={oconclusion.c2.quote} link={links.c2} mdxType="ATooltip">Operational Conclusion 2</ATooltip> and <ATooltip tip={oconclusion.c3.quote} link={links.c3} mdxType="ATooltip">3</ATooltip>, PALMS 
  has no mechanism for reporting attendance.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      